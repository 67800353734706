import React from "react";
import { Typewriter, useTypewriter } from 'react-simple-typewriter'
import Logo from "../image/dp.png"
import LetsGo from "../components/letsgo";
import { useDispatch, } from "react-redux"
import {selectedActions} from "../store/selectedslice"
import ParticlesBackground from "../components/particlebackground";
import {motion} from 'framer-motion'
import { Canvas } from "@react-three/fiber";
import Profile from "../components/profile";


function LandingTest() {


    const dispatch = useDispatch()
    const handlePageSwitch = () => {
        dispatch(selectedActions.changename("details"))

    }
    

    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.8}} className="flex flex-col items-center justify-center w-screen h-screen text-center bg-black md:bg-gradient-to-r md:from-sky-900/60 md:via-black md:to-sky-900/50 grow">
            {/* <h1>
                HI <span className="text-2xl font-bold text-red-800">BINEET</span>, This is going to be your cv . But for now adios.
            </h1>
            <p>
                So the page is under construction
            </p> */}
            <ParticlesBackground/>

            <div className="z-10 bg-white">
                <Canvas shadows camera={{position:[0,2,5],fov:75, far:500}}>
                    
                    <Profile/>
                </Canvas>
                {/* <img src={Logo} className="h-40 md:h-80" /> */}

            </div>
            <div>
                <h1 className="px-2 text-3xl font-bold text-gray-300 lg:text-6xl md:text-4xl">
                    <Typewriter
                        words={["Hii there!!","I am BINEET", "Working as a Ph.D. scholar at ILS", "I do Bioinformatics", "I do Computational Biology","I am fascinated about Networks and Graphs","< I love Programming />"]}
                        loop={true}
                        cursor
                        cursorStyle="|"

                    />
                </h1>
            </div>
            <div className="pt-20 font-bold text-white">
                {/* <div className="px-6 py-2 text-2xl border rounded-3xl">
                    Let's Go
                </div> */}

            </div>
            <div onClick={handlePageSwitch}>
                <LetsGo/>
            </div>
        </motion.div>
    )
}


export default LandingTest