import React from 'react'
import { socialDetails, academicDetails } from './informations'
import {motion} from 'framer-motion'

function ContactMe() {
    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.4}} className='flex flex-col justify-around text-center grow my-9'>
            <div>
                <h1 className='mb-3 text-2xl font-bold'>Emails and Other Stuffs</h1>
                <div className='flex flex-col justify-around mx-2 rounded-lg bg-black/30'>
                    <div className='py-3 my-3'>
                        <h1 className='font-bold'>Professional Email</h1>
                        <a className='flex justify-center py-1 mx-3 mt-3 rounded-lg bg-white/30'>bineet@ils.res.in</a>
                    </div>
                    <div className='py-3 my-3'>
                        <h1 className='font-bold'>Personal Email</h1>
                        <a className='flex justify-center py-1 mx-3 mt-3 rounded-lg bg-white/30'>bineetkumarmohanta@gmail.com</a>
                    </div>
                </div>
            </div>
            <div>
                <h1 className='mb-3 text-2xl font-bold'>Socials Links</h1>
                <div className='flex flex-wrap justify-around px-2 py-4 mx-3 rounded-lg bg-black/30 dark:border-b-2 dark:border-purple-600'>
                    {socialDetails.map((val,key) => {
                        return(
                            <motion.a animate={{ opacity: 1, scale: 1 }}
                            transition={{
                            
                                duration:0.4,
                                type:'spring',
                                damping:10,
                                stiffness:100,

                            }}
                            initial={{ opacity: 0, scale: 0.1 }} href={val.link} className="flex flex-col items-center hover:text-sky-500 dark:hover:text-purple-600">
                                <div className='text-2xl '>
                                    {val.logo}
                                </div>
                                <div>
                                    {val.name}
                                </div>

                            </motion.a>
                        )
                    })}

                </div>
            </div>
            <div>
                <h1 className='mb-3 text-2xl font-bold'> Academic Links</h1>
                <div className='flex justify-around py-4 mx-3 rounded-lg bg-black/30 dark:border-b-2 dark:border-purple-600'>
                    {academicDetails.map((val,key) => {
                        return(
                            <motion.a href={val.link} animate={{ opacity: 1, scale: 1 }}
                            transition={{
                            
                                duration:0.4,
                                type:'spring',
                                damping:10,
                                stiffness:100,

                            }}
                            initial={{ opacity: 0, scale: 0.1 }} className="flex flex-col items-center hover:text-sky-500 dark:hover:text-purple-600">
                                <div className='text-2xl '>
                                    {val.logo}
                                </div>
                                <div>
                                    {val.name}
                                </div>

                            </motion.a>
                        )
                    })}

                </div>
            </div>
        </motion.div>
    )
}

export default ContactMe