import React from "react"
import { educations } from './informations'
import { motion } from "framer-motion"
import { Tilt } from 'react-tilt'

function Education() {

    const defaultOptions = {
        reverse:        false,  // reverse the tilt direction
        max:            35,     // max tilt rotation (degrees)
        perspective:    1500,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:          1.2,    // 2 = 200%, 1.5 = 150%, etc..
        speed:          1000,   // Speed of the enter/exit transition
        transition:     true,   // Set a transition on enter/exit.
        axis:           null,   // What axis should be disabled. Can be X or Y.
        reset:          true,    // If the tilt effect has to be reset on exit.
        easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    }
    
    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:0.6}} className="flex flex-col w-full ">
            <div className="flex flex-col h-full mx-4 my-4 border-l-4 border-purple-600">
                {educations.map((val, key) => {
                    const bar_width = val.conf
                    return (
                        <div className="flex gap-4 grow">
                            <div class="bg-purple-600 w-6 h-6 flex items-center justify-center rounded-full -ml-3">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" class="text-white w-3 h-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path>
                                </svg>
                            </div>
                            <Tilt options={defaultOptions} className="flex flex-col justify-center py-4 pl-5 my-4 grow bg-black/40 dark:border rounded-xl">
                                <h1 className="text-lg font-bold md:text-2xl">
                                    {val.inst}
                                </h1>
                                <p>{val.location}</p>
                                <div>
                                    <p className="font-bold">{val.degree}</p>
                                    <p>{val.time}</p>
                                </div>
                                <p>
                                    {val.subject}
                                </p>
                                <div>{val.other}</div>
                            </Tilt>
                        </div>

                    )
                })}
            </div>
        </motion.div>
    )
}

export default Education