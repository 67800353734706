import { Canvas } from "@react-three/fiber";
import { Avatar } from "./avatar";
import { OrbitControls } from "@react-three/drei";


const Profile = () => {
    return (
        <>
            <OrbitControls />
            <group position-y={-1}>
            <Avatar />
            </group>
            
            <ambientLight intensity={2} />
        </>
    )
}

export default Profile



